import React, { useState } from 'react';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout } from 'antd';
import Header from '../header/Header';

const { Content } = Layout;

const AdminLayout = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [collapsedNav, setCollapsedNav] = useState(false);

  const handleCollapsedNav = () => {
    setCollapsedNav(false);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <NavMenu
        isCollapsed={isCollapsed}
        setCollapsed={setCollapsed}
        collapsedNav={collapsedNav}
        handleCollapsedNav={handleCollapsedNav}
      />

      <Layout>
        <Header
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          collapsedNav={collapsedNav}
          setCollapsedNav={setCollapsedNav}
          handleCollapsedNav={handleCollapsedNav}
        />
        <Content className='content-space'>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
